<template>
  <div v-if="reqDetail !== null" class="mt-4">
    <div  class="relative mb-4 card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class="flex justify-center items-center">
        <h1 v-if="reqDetail.requestTitle !== ''" class="heading-1 text-center">{{reqDetail.requestTitle}}</h1>
        <h1 v-else class="heading-1 text-center">Request for {{reqDetail.companyName}}</h1>
      </div>
      <div class="absolute right-2 top-2">
        <div class="relative">
          <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
            <i class="fas fa-ellipsis-v"></i>
          </div>
          <div v-if="showMenuList">
            <div v-click-outside="closeList">
              <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-32">
                  <div class="p-2 text-text2 font-medium text-center">
                    <div class="p-2 cursor-pointer hover:bg-blue-50" v-if="!reqDetail.isArchived && !reqDetail.isConverted" @click="archiveRequest()">
                      <span class="py-1">Archive</span>
                    </div>
                    <div class="p-2 cursor-pointer hover:bg-blue-50" @click="editRequest()">
                      <span class="py-1">Edit</span>
                    </div>
                    <div class="p-2 cursor-pointer hover:bg-red-50" @click="deleteReqConfirm = true">
                      <span class="py-1 text-error">Delete</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 flex flex-wrap gap-4 card rounded-xl bg-white p-4">
      <!-- <Button v-if="!reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted && !reqDetail.isScheduled && (reqDetail.startTime === '' || reqDetail.endTime === '')"  :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Schedule Assessment'" @buttonAction="AddUpdateSheduleBtn"/>
      <Button v-if="!reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted && reqDetail.isScheduled && (reqDetail.startTime !== '' || reqDetail.endTime !== '')"  :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Complete Assessment'" @buttonAction="completeAsmnt(true)"/> -->
      <Button v-if="!reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted && !reqDetail.isScheduled "  :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Schedule Assessment'" @buttonAction="AddUpdateSheduleBtn"/>
      <Button v-if="!reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted && reqDetail.isScheduled "  :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Complete Assessment'" @buttonAction="completeAsmnt(true)"/>
      <div v-if="reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted" class="bg-primary p-2 rounded-xl text-white hover:shadow-xl cursor-pointer hover:bg-opacity-75 px-4" @click="completeAsmnt(false)"><i class="fa fa-check"></i><span class="px-2">Completed</span></div>
      <div v-if="reqDetail.isArchived && !reqDetail.isConverted" class="bg-gray4 p-2 rounded-xl text-white hover:shadow-xl cursor-pointer hover:bg-opacity-75 px-4" @click="archiveRequest()"><span class="px-2">Unarchive</span></div>
      <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Convert to Quote'" @buttonAction="convertToQuote"/>
      <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Convert to Job'" @buttonAction="convertToJob"/>
      <Button v-if="this.reqDetail.requestLinkHashExpiry === ''" :btnSize="'medium'" :textColor="'white'" :btnColor="'secondary'" :btnText="'Generate Request Share Link'" @buttonAction="GenerateJob()"/>
      <div class="relative" v-if="this.reqDetail.requestLinkHashExpiry !== ''">
        <button :class="!isCard ? 'heading-7' : ''" class="bg-secondary text-white px-3 py-2 rounded-xl hover:shadow-xl" @click.stop="showMenuList1 = true">
          Share Request
        </button>
        <div v-if="showMenuList1">
          <div v-click-outside="closeList1">
            <div class=" bg-white absolute z-10 -right-24 shadow-2xl rounded top-10 w-auto">
                <div class="p-2 text-text2 font-medium text-center">
                  <div class="p-2 cursor-pointer text-primary hover:bg-gray-50 flex items-center" @click="copyTextNoInput(reqDetail.reqLinkHash)">
                    <i class="fas fa-copy"></i>
                    <span ref="jobUrl" class="py-1 pl-2" >{{reqDetail.reqLinkHash}}</span>
                  </div>
                  <div class="divider"></div>
                  <div class="p-2 cursor-pointer hover:bg-gray-50" @click="sharebutton(reqDetail.reqLinkHash)">
                    <span class="py-1 text-text2"><i class="fas fa-external-link text-primary heading-5 cursor-pointer"></i><span class="pl-2">Open URL</span></span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class="relative">
              <p>Request #{{reqDetail.requestId}}</p>
              <!-- <span class=" text-primary cursor-pointer" @click.stop="customerDetail">{{reqDetail.companyName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span> -->
              <div class="flex justify-between">
                <div class="">
                <span class="cursor-pointer text-primary font-semibold" @click.stop="customerDetail()">{{reqDetail.companyName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span>
                </div>
                <div v-if="reqDetail.customerHotNotes !== ''" class=" text-right">
                  <span class="cursor-pointer text-primary heading-7" @click.stop="showHotNOtes = !showHotNOtes"> {{showHotNOtes ? 'Hide' : 'Show'}} hot notes</span>
                </div>
              </div>
              <div v-if="showHotNOtes" class="">
                  <span class="cursor-pointer text-secondary">
                    {{reqDetail.customerHotNotes}}
                  </span>
                </div>
              <p class="">Created Date: {{reqDetail.addedDate| dayDateType }}</p>
            </div>
          </div>
          <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <!-- <div class="mb-4 flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">
                  <span v-if="reqDetail.fullAddress !== ''">{{reqDetail.fullAddress}}</span>
                  <span v-else>
                    <span v-if="reqDetail.addressLine1 !== ''">{{reqDetail.addressLine1}}, </span> 
                    <span v-if="reqDetail.addressLine2 !== ''">{{reqDetail.addressLine2}}, </span> 
                    <span v-if="reqDetail.city !== ''">{{reqDetail.city}}, </span> 
                    <span v-if="reqDetail.state !== ''">{{reqDetail.state}}, </span> 
                    <span v-if="reqDetail.country !== ''">{{reqDetail.country}}-</span> 
                    <span v-if="reqDetail.zip !== ''">{{reqDetail.zip}}</span>
                  </span>
                  </p>
                  <p  class="text-text1 heading-6">
                    <span v-if="reqDetail.houseNo !== ''">#: {{reqDetail.houseNo}}</span>
                    <span v-if="reqDetail.floorNumber !== ''"> (Floor: {{reqDetail.floorNumber}})</span>
                  </p>
                  <p v-if="reqDetail.buildingName !== ''" class="text-text1 whitespace-pre-wrap">Bldg: {{reqDetail.buildingName}}</p>
                </div>
              </div> -->
              <div class="mb-4 flex whitespace-nowrap justify-between">
                  <div class="flex items-start">
                    <div>
                      <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2">:</span></span>
                    </div>
                    <div class="pl-1 relative">
                        <p v-if="reqDetail.fullAddress !== ''" class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap" @click="showJobDetail = !showJobDetail">
                        {{reqDetail.fullAddress}}
                        </p>
                        <p v-else class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap" @click="showJobDetail = !showJobDetail">
                          <span v-if="reqDetail.addressLine1 !== ''">{{reqDetail.addressLine1}}, </span> 
                          <span v-if="reqDetail.addressLine2 !== ''">{{reqDetail.addressLine2}}, </span> 
                          <span v-if="reqDetail.city !== ''">{{reqDetail.city}}, </span> 
                          <span v-if="reqDetail.state !== ''">{{reqDetail.state}}, </span> 
                          <span v-if="reqDetail.country !== ''">{{reqDetail.country}}-</span> 
                          <span v-if="reqDetail.zip !== ''">{{reqDetail.zip}}</span>
                        </p>
                        <div v-if="showJobDetail">
                          <p  class="text-text1 heading-6">
                            <span v-if="reqDetail.houseNo !== ''">#: {{reqDetail.houseNo}}</span>
                            <span v-if="reqDetail.houseNo !== ''"> (Floor: {{reqDetail.floorNumber}})</span>
                          </p>
                          <p v-if="reqDetail.buildingName!== ''" class="text-text1 ">Bldg: {{reqDetail.buildingName}}</p>
                          <p v-if="reqDetail.directionNote!== ''" class="text-text1 whitespace-pre-wrap">Directions: {{reqDetail.directionNote}}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="flex items-start">
                      <span @click="showJobDetail = !showJobDetail" v-if="showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-up"></i></span>
                      <p @click="showJobDetail = !showJobDetail" v-if="!showJobDetail" class="text-primary cursor-pointer"><i class="fas fa-chevron-down"></i></p>
                    </div> -->
                </div>
            </div>
          </div>
          <div v-if="reqDetail.latitude === 0 && reqDetail.longitude === 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <div class="flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">At Office</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.instructions !== ''" class="text-text1 font-bold mb-2 heading-4 ">Instructions</p>
              <p v-else class="text-text1 heading-4 ">No Instructions</p>
              <p>{{reqDetail.instructions}}</p>
            </div>
          </div> -->
          <!-- <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.serviceDetail !== ''" class="text-text1 font-bold mb-2 heading-4 ">Service Details</p>
              <p v-else class="text-text1 heading-4 ">No Service Details</p>
              <p class="whitespace-pre-line">{{reqDetail.serviceDetail}}</p>
            </div>
          </div> -->
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p v-if="reqDetail.serviceDetail !== ''" class="  font-bold mb-2">Service Details</p>
              <p v-else class="  font-bold">No Service Details</p>
              <!-- <p class="text-gray4 whitespace-pre-line">{{reqDetail.serviceDetail}}</p> -->
              <span class="mb-0 text-gray4 whitespace-pre-line">
                {{reqDetail.serviceDetail === '' ? '-' : (reqDetail.serviceDetail.length > 125)? readMoreNote ?  reqDetail.serviceDetail : reqDetail.serviceDetail.substr(0, 125) : reqDetail.serviceDetail}}
                <span v-if="reqDetail.serviceDetail.length > 125" class="text-primary cursor-pointer heading-7" @click="readMoreNote = !readMoreNote">
                  {{readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
              </span>
              <span @click="geReqDetail()" class="pl-2 cursor-pointer text-primary absolute top-0 right-7"><i class="fa-solid fa-refresh"></i></span>
              <span @click="updateJob('notes', reqDetail.serviceDetail, reqDetail.serviceAttachmentDetail.attachmentList)" class="pl-2 cursor-pointer text-primary absolute top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
            </div>
            <div v-if="reqDetail.serviceAttachmentDetail.attachmentList !== null" class="mt-2">
               <!-- <p class="text-primary heading-6 mb-2">Attachments:</p> -->
               <div class="flex gap-4" style="flex-wrap: wrap;">
                <div class=" card rounded-sm border-t border-gray-100 bg-white attachment-card cursor-pointer group relative" style="width: 110px; height: 76px"  v-for="(attach, a) in reqDetail.serviceAttachmentDetail.attachmentList " :key="a" @click="showFullImage(attach)">
                  <div v-if="tekeType(attach.fileType) === 'image'" class="text-center flex justify-center">
                    <img style="height: 60px;width: auto;" :src="`${reqDetail.serviceAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                    <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                  </div>
                  <div v-if="tekeType(attach.fileType) === 'video'" class="flex justify-center items-center w-full">
                      <img style="height: 60px;width: auto;" :src="`${reqDetail.serviceAttachmentDetail.pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                      <p v-if="attach.addedById === 0"><i  class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                  </div>
                  <div v-if="tekeType(attach.fileType) === 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                      <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                  </div>
                  <div v-if="tekeType(attach.fileType) !== 'image' && tekeType(attach.fileType) !== 'video' && tekeType(attach.fileType) !== 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                      <p v-if="attach.addedById === 0"><i class="absolute right-0 top-0 fas fa-users text-primary bg-white border border-gray1 p-1 rounded-full heading-8"></i></p>
                  </div>
                  <div class="text-text2 text-center heading-8">{{attach.uploadedOn | dateFilter1}}</div>
                </div>
               </div>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="card rounded-xl bg-white mb-4 p-4">
            <p class="text-text2 mb-2 heading-5 ">Availability 
              <span v-if="reqDetail.availabilityDate !== ''" >{{reqDetail.availabilityDate | DatePickerDateHeader}}</span>
              <span v-if="reqDetail.otherAvailDate !== ''" > & {{reqDetail.otherAvailDate | DatePickerDateHeader}}</span>
            </p>
            <!-- <div class="flex items-center">
              <div class="text-gray4 w-56">Availability date:</div>
              <div v-if="reqDetail.availabilityDate !== ''" >{{reqDetail.availabilityDate | DatePickerDateHeader}}</div>
              <div v-else>--</div>
            </div>
            <div class="flex items-center">
              <div class="text-gray4 w-56">Alternate availability date:</div> 
              <div v-if="reqDetail.otherAvailDate !== ''" >{{reqDetail.otherAvailDate | DatePickerDateHeader}}</div>
              <div v-else>--</div>
            </div> -->
            <div class="flex items-center">
              <p><span class="text-text2 heading-5 pr-2">Preferred arrival times</span></p>
              <div v-if="anyTime" class="chip px-2 mx-1">
                <span class="text-text2" style="font-size:14px">Any Time</span>
              </div>
              <div v-if="morning" class="chip px-2 mx-1">
                <span class="text-text2" style="font-size:14px">Morning</span>
              </div>
              <div v-if="afternoon" class="chip px-2 mx-1">
                <span class="text-text2" style="font-size:14px">Afternoon</span>
              </div>
              <div v-if="evening" class="chip px-2 mx-1">
                <span class="text-text2" style="font-size:14px">Evening</span>
              </div>
            </div>
            <!-- <div class="flex items-center">
              <div class="w-80">Any Time</div>
              <span class=" text-primary heading-4" v-if="anyTime"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Morning</div>
              <span class=" text-primary heading-4" v-if="morning"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Afternoon</div>
              <span class=" text-primary heading-4" v-if="afternoon"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Evening</div>
              <span class=" text-primary heading-4" v-if="evening"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div> -->
          </div>
          <div  class="card rounded-xl bg-white mb-4 p-4 " v-if="(reqDetail.startTime !== '' && reqDetail.endTime !== '') || reqDetail.isUnschedule">
            <div class=" relative">
              <p class="text-text1 font-bold mb-2 heading-4 ">Assessment details</p>
                <div v-if="reqDetail.isUnschedule" class="mb-2">
                  <span class="text-primary"><i class="fa-solid fa-calendar-days heading-5"></i></span> Unscheduled
                </div>
                <div v-else class="mb-2">
                  <span class="text-primary mr-2"><i class="fa-solid fa-calendar-days heading-5"></i></span> 
                  <span v-if="!allDay" class="text-gray4">{{reqDetail.startTime | logTimeShow}} - {{reqDetail.endTime | leaveTimeFilterForActivity}}</span>
                  <span v-else class="text-gray4">{{reqDetail.startTime | dateFilterInvoiceRegularFormate}}: Full-Day</span>
                </div>
                <div v-if="reqDetail.assignToList.length > 0">
                  <span class="text-gray3">Assign to: </span>
                  <span v-for="(user, index) in reqDetail.assignToList" :key="index">
                    <span v-if="logedInUser === user.userDetailId">Me</span>
                    <span v-else>{{user.firstName}} {{user.lastName}}</span>
                    <span v-if="index < reqDetail.assignToList.length -1">, </span>
                  </span>
                </div>
                <span @click="addUpdateshedule = !addUpdateshedule" class=" cursor-pointer absolute text-primary top-0 right-0"><i class="fa-solid fa-pen-to-square"></i></span>
                <div class="pt-2">
                  <p v-if="reqDetail.instructions !== ''" class="text-text1 font-bold mb-2 heading-4 ">Instructions</p>
                  <p v-else class="text-text1 heading-4 ">No Instructions</p>
                  <p>{{reqDetail.instructions}}</p>
                </div>
            </div>
          </div>
          <div  class="card rounded-xl bg-white mb-4 p-4 " v-else>
            <div class=" relative" @click="addUpdateshedule = !addUpdateshedule" >
              <div class="text-primary cursor-pointer">
                <span class="text-primary pr-2"><i class="fa-solid fa-calendar-days heading-5"></i></span> Add Schedule
              </div>
            </div>
          </div>
            <div v-if="reqDetail !== null" class="card rounded-xl bg-white mb-4 p-4">
              <NotesList :detailId="parseInt(this.$route.params.requestId)" :requestType="'request'" :noteTitle="'Request Note'" />
            </div>
        </div>
      </div>
    </div>
    <UpdateInsruction v-if="updateInstruction" :pathPrefix="reqDetail.serviceAttachmentDetail.pathPrefix" :note="updateValue" :attachment="insArray" :reqType="reqType" />
    <FullImage :fileDate="fileDate" v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" :isFromContactId="isFromContactId" />  
    <AddUpdateShedule :requetsObj="reqDetail" :requestId="reqDetail.requestId" :selectedTeam="selectedTeam" :assementObj="assementObj" :addUpdateshedule="true" v-if="addUpdateshedule" />
    <ConfirmBox v-if="deleteReqConfirm"  :message="'Want to delete this request'" :title="'Are you sure?'" />
  </div>
</template>
<script>
var moment = require('moment')
import UpdateInsruction from '@/View/job/components/updateServiceName.vue';
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import NotesList from '@/View/job/visit/NotesList.vue';
import AddUpdateShedule from '@/View/request/components/AddUpdateShedule.vue';
import {FilterPermissions} from '@/utils/Permissions.js'
import FullImage from '../endUserNewFlow/components/FullImage.vue';
export default {
  name: "lead",
  components: {
    UpdateInsruction,
    FullImage,
    ConfirmBox,
    NotesList,
    Button,
    AddUpdateShedule,
  },
  data() {
    return {
      showHotNOtes: false,
      fileDate: '',
      isFromContactId: 0,
      readMoreNote: false,
      showJobDetail: false,
      isCard: true,
      updateInstruction: false,
      fileName: '',
      fileType: '',
      fileVaultId: '',
      shoFullImage: false,
      canQuotePermission: FilterPermissions('quote'),
      canJobPermission: FilterPermissions('job'),
      isCustomerPermission: FilterPermissions('customer'),
      isLeadPermission: FilterPermissions('lead'),
      orgDetail: null,
      showMenuList1: false,
      showMenuList: false,
      overView: null,
      reqDetail: null,
      anyTime: false,
      addUpdateshedule: false,
      morning: false,
      afternoon: false,
      evening: false,
      insArray: [],
      deleteReqConfirm: false,
      allDay: false,
      selectedTeam: [],
      logedInUser: 0,
       assementObj: {
        instructions : '',
        isUnschedule : true,
        isAnyTime : false,
        startDateTime: '',
        endDateTime: '',
        isSchedule: false,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    this.$root.$on('updateJobHandler', (data) => {
      if (data) {
        this.geReqDetail()
        }
        this.updateInstruction = false
    })
    setTimeout(() => {
      let temp = FilterPermissions('request')
      if (temp) {
        let detailObj = JSON.parse(localStorage.getItem('jobUserInfo'))
        this.logedInUser = detailObj.userDetailId
         this.geReqDetail()
         this.geoverviewReq()
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to show Request detail`})
      }
    }, 200);
     this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.deleteReqConfirm) {
        this.deleteReq()
      }
      document.body.style = 'overflow: visible;'
      this.deleteReqConfirm = false
    })
    this.$root.$on('fullImageHandlerEnd', () => {
        this.shoFullImage = false
    })
     this.$root.$on('closeScheduleHandler', (response) => {
      if (response) {
        this.geReqDetail()
      }
        this.addUpdateshedule = false
    })
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
    this.$root.$off("updateJobHandler");
  },
  methods: {
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
      if (window.innerWidth <= 640) {
        this.isCard = false
      } else {
        this.isCard = true
      }
    },
    updateJob (type, value, data) {
      this.reqType = type
      this.updateValue = value
      if (data !== null) {
        this.insArray = this.attachmentArray
      }
      this.updateInstruction = true
    },
    tekeType (fileType) {
      let temp = fileType.split('/')
        let type = ''
        if (temp[0] === 'image' ) {
            type = 'image'
        } else if (temp[0] === 'application' && temp[1] === 'pdf') {
          type = 'pdf'
        } else if (temp[0] === 'video' ) {
          type = 'video'
        }
        return type
    },
    copyTextNoInput () {
      this.selectText(this.$refs.jobUrl)
      document.execCommand('copy')
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Job url copied', color: 'success'})
    },
    selectText (element) {
      var range
      if (document.selection) {
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    sharebutton (data) {
      // // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
      // let url = this.$router.resolve({name: 'jd', params: {infoId: 0}})
      // window.open(url.href, '_blank')
      window.open(data, '_blank')
    },
    GenerateJob () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Generating...'})
      MyJobApp.GenerateRequestShareLink(
        this.reqDetail.requestId,
        () => {
          this.geReqDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    closeList () {
      this.showMenuList = false
    },
    closeList1 () {
      this.showMenuList1 = false
    },
    convertToJob () {
      if (this.canJobPermission) {
        this.$router.push({name: 'addNewJob', query: {customerId: this.reqDetail.customerId, requestType: 'request', detailId:  parseInt(this.$route.params.requestId)}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Convert Job`})
      }
    },
    convertToQuote () {
      if (this.canQuotePermission) {
        this.$router.push({name: 'EditQuote', params: {quoteId: 0} , query: {customerId: this.reqDetail.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to Convert Quote`})
      }
    },
    customerDetail () {
      if (this.isCustomerPermission || this.isLeadPermission) {
        this.$router.push({name: 'customerDetails', params: {customerId: this.reqDetail.customerId}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Customer Detail`})
      }
    },
    deleteReq () {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.deleteRequest(
        parseInt(this.$route.params.requestId),
        (response) => {
            this.$router.push({ name: 'request' }).catch(()=>{});
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    AddUpdateSheduleBtn () {
      this.addUpdateshedule = true
    },
    showFullImage (data) {
      this.fileVaultId = data.fileVaultId
      this.fileType = this.tekeType(data.fileType)
      this.fileName = data.displayFileName
      this.isFromContactId = data.addedById
      this.fileDate = data.uploadedOn
      this.shoFullImage = true
    },
    completeAsmnt (data) {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.CompleteAssessment(
        parseInt(this.$route.params.requestId),
        data,
        (response) => {
          this.geReqDetail()
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    editRequest () {
      this.$router.push({name: 'editRequest', params: {requestId: parseInt(this.$route.params.requestId)}})
    },
    archiveRequest () {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.RequestArchive(
        parseInt(this.$route.params.requestId),
        (response) => {
          this.geReqDetail()
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    geoverviewReq() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.overviewReq(
        parseInt(this.$route.params.requestId),
        (response) => {
          this.overView = response.Data
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    geReqDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.getRequestDetail(
        parseInt(this.$route.params.requestId),
        (response) => {
          this.reqDetail = response.Data;
          let temp = response.Data.arrivalTime.split(",")
          this.selectedTeam = response.Data.assignToList
          this.assementObj.instructions = response.Data.instructions
          this.assementObj.isUnschedule = response.Data.isUnschedule
          
          this.assementObj.startDateTime = response.Data.startTime
          this.assementObj.endDateTime = response.Data.endTime
          this.assementObj.isSchedule = true
          temp.map(text => {
            if (text === 'morning') {
              this.morning = true
            }
            if (text === 'anyTime') {
              this.anyTime = true
            }
            if (text === 'afternoon') {
              this.afternoon = true
            }
            if (text === 'evening') {
              this.evening = true
            }
          })
          if (this.reqDetail.serviceAttachmentDetail.attachmentList !== null) {
            this.attachmentArray = []
            this.reqDetail.serviceAttachmentDetail.attachmentList.map(a => {
                let temp = a.fileType.split('/')
                let type = ''
                if (temp[0] === 'image' ) {
                    type = 'image'
                } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                  type = 'pdf'
                } else if (temp[0] === 'video' ) {
                  type = 'video'
                }
                this.attachmentArray.push({...a, docType: type})
            })
          }
          var startTime1 = moment(new Date());
          var endTime2 = moment(new Date(this.reqDetail.requestLinkHashExpiry));

          if (endTime2.isBefore(startTime1)) {
            this.reqDetail.reqLinkHash = '';
            this.reqDetail.requestLinkHashExpiry = '';
          }
          if (this.reqDetail.latitude === 0 && this.reqDetail.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            MyJobApp.getOrgDetail(
              (response) => {
                this.orgDetail = response.Data
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
         if (response.Data.startTime !== "" && response.Data.endTime) {
          var startTime = moment(response.Data.startTime,)
          var endTime = moment(response.Data.endTime,)
          var hoursDiff = endTime.diff(startTime, 'hours')
          var minutesDiff = endTime.diff(startTime, 'minutes') % 60
          if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
            this.allDay = true
            this.assementObj.isAnyTime = true
          } else {
            this.allDay = false
          }
         }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
  },
};
</script>
<style scoped>
.chip {
  display: inline-block;
  background-color: #eaeaea;
  border-radius: 10px;
}
.attachment-card2 {
  display: flex;
  align-items: center;
  position: relative;
}
.scroll-img-view{
  height: 104px;
}
.zIndex {
  z-index: 100 !important;
}
</style>